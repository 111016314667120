import * as Sentry from '@sentry/vue';
import environment from '@/../config/env';

const { sentryDSN, serverEnv } = environment;
const isLocal = process.env.NODE_ENV !== 'production';

export const sentryInit = (app) => {
  Sentry.init({
    app,
    dsn: sentryDSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    release: '0.1.0',
    environment: serverEnv,
    // Performance Monitoring
    tracesSampleRate: 0.2, //  Capture 20% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    /* tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/], */
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: !isLocal,
  });
};
